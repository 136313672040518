<template>
    <lassoLayout>
        <div class="verification-request-submitted">
            <div class="content-page">
                <v-container>
                    <div class="block_is_this_you">
                        <h1>
                            Submitted!
                        </h1>
                        <div class="description">
                            Your verification request is being reviewed. Please allow a few days for processing.
                        </div>
                        <div class="spaceship"></div>
                        <div class="action_buttons">
                            <router-link :to="{name: 'explore'}" class="tetriatary_btn medium" v-ripple>
                                <span>Home</span>
                            </router-link>
                            <button type="button" @click="donate" class="c2a_btn medium fizz-effect" v-ripple>
                                Donate
                                <div v-for="n in 8" :key="n" class="bubble-stream"></div>
                            </button>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout.vue";

export default {
    name: 'is-this-you',
    components: {
        lassoLayout
    },
    data() {
        return {}
    },
    computed: {},
    mounted() {
    },
    methods: {
        donate() {
            window.open('https://www.patreon.com/psychurch', '_blank')
        }
    }
}
</script>

<style lang="scss">
.fizz-effect {
    @include button-fizz-effect;
}
</style>
